<template>
	<div>
		<div v-show="tableShow" ref="template">
			<slot></slot>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'e-easy-print',
		components: {},
		props: {
			tableShow: {
				type: Boolean,
				default: false
			},
			title: {
				type: String,
				default: 'file'
			},
			beforeCopy: Function,
			beforePrint: Function
		},
		data () {
			return {
				isReady: false
			}
		},
		computed: {
			element_id () {
				return `easy-print-iframe`
			}
		},
		async mounted () {
			await this.init()
			this.isReady = true
		},
		methods: {
			init () {
				return new Promise(resolve => {
					let printI = document.getElementById(this.element_id)
					if (printI) {
						return resolve()
					}
					printI = document.createElement('iframe')
					printI.id = this.element_id
					printI.style.position = 'fixed'
					printI.style.width = '0'
					printI.style.height = '0'
					printI.style.top = '-100px'
					printI.onload = async () => {
						await this.loadStyle()
						resolve()
					}
					document.body.appendChild(printI)
				})
			},
			async print () {
				const html = this.$refs.template.innerHTML
				await this.waitInit()
				// if (typeof this.beforeCopy === 'function') {
				// 	this.beforeCopy()
				// }
				let $iframe = document.getElementById(this.element_id)
				$iframe.contentDocument.body.innerHTML = html
				await this.waitElements($iframe.contentDocument.body)
				// await this.waitImages($iframe.contentDocument)
				// if (typeof this.beforePrint === 'function') {
				// 	this.beforePrint()
				// }
				setTimeout(() => {
					$iframe.contentWindow.print()
					// document.getElementById(this.element_id).remove()
				}, 500)
			},
			waitInit () {
				return new Promise(resolve => {
					const callback = () => {
						setTimeout(() => {
							if (this.isReady) {
								resolve()
							} else {
								callback()
							}
						}, 50)
					}
					callback()
				})
			},
			waitElements (body) {
				return new Promise((resolve, reject) => {
					const selector = '*'
					const totalEls = this.$refs.template.querySelectorAll(selector).length
					let counter = 0

					const callback = () => {
						setTimeout(() => {
							counter++
							const currentEls = body.querySelectorAll(selector).length
							// if (counter >= 200) {
							// 	reject(new Error('An error occured while printing document'))
							// } else
							 if (currentEls < totalEls) {
								callback()
							} else {
								resolve()
							}
						}, 50)
					}
					callback()
				})
			},
			waitImages (doc) {
				return new Promise(resolve => {
					const imgs = doc.querySelectorAll('img')
					const len = imgs.length
					let counter = 0
					// Trick pour l'attente du chargement des images
					const incrementCounter = () => {
						counter++
						if (counter >= len) {
							resolve()
						}
					}
					[].forEach.call(imgs, img => {
						if (img.complete) {
							incrementCounter()
						} else {
							img.addEventListener('load', incrementCounter, false)
							img.addEventListener('error', incrementCounter, false)
						}
					})
				})
			},
			loadStyle () {
				return new Promise(resolve => {
					let printI = document.getElementById(this.element_id)
					// Trick pour l'attente du chargement des images
					let counter = 0
					let validStyles = 0
					const incrementCounter = () => {
						counter++
						if (counter >= validStyles) {
							resolve()
						}
					}
					let styles = document.querySelectorAll('link, style')
					for (let i = 0; i < styles.length; i++) {
						let baseEl = styles[i]
						let frameEl = document.createElement(baseEl.tagName.toLowerCase())
						if (baseEl.href && !baseEl.href.includes('css')) {
							continue
						}
						validStyles++
						frameEl.addEventListener('load', incrementCounter, false)
						frameEl.addEventListener('error', incrementCounter, false)
						frameEl.setAttribute('rel', 'stylesheet')
						frameEl.setAttribute('type', baseEl.type || 'text/css')
						frameEl.setAttribute('href', baseEl.href)
						frameEl.setAttribute('media', 'all')
						frameEl.innerHTML = baseEl.innerHTML
						printI.contentDocument.head.appendChild(frameEl)
					}

					// imprime en format paysage, TODO : props landscape par défaut à false
					// let frameEl = document.createElement('style')
					// frameEl.setAttribute('rel', 'stylesheet')
					// frameEl.setAttribute('type', 'text/css')
					// frameEl.setAttribute('href', undefined)
					// frameEl.setAttribute('media', 'all')
					// frameEl.innerHTML = "@page { size: landscape; }"
					// printI.contentDocument.head.appendChild(frameEl)
				})
			}
		}
	}
</script>